.fullscreen {
  // Position fixed to make it go ABOVE the menu...
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  pointer-events: none; // Fullscreen div is blocking inputs

  &>div {
    pointer-events: all;
  }
}

.fullscreen__header {
  display: flex;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  // justify-content: space-between;
  transform: translateY(-10px);
  padding: 10px 10px 20px;
  flex: 0 0 auto;
  transition: opacity 300ms ease 0s, -webkit-transform 300ms ease 0s, transform 300ms ease 0s;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  // &:hover {
  //   opacity: 1;
  // }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.fullscreen__header__button,
a.fullscreen__header__button {
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  display: inline-flex;
  height: 44px;
  position: relative;
  width: 44px;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: 0px center;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 0px;
  font-size: 24px;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}

.react-images__positioner>div {
  width: 100%; /* Fixes invalid modal navbar sizes */
}