.carousel {
  &__button {
    border: 0px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 10px;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    width: 44px;
    font-weight: bold;
    outline: none;
    opacity: 0;
    transition: all ease-in-out 0.5s;
  }

  &__thumbnails {
    width: 800px;
    max-width: 95%;
    margin: 0 auto;
    text-align: center;
  }

  &__thumbnail {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid transparent;

    &.active {
      border: 1px solid #ddd;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &:hover {
      border: 1px solid white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }

  .slider-control-bottomcenter {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    .slider-control-bottomcenter {
      display: none;
    }
  }
}

.slider .carousel__button {
  opacity: 1;
}
